import './App.css';
import React from 'react';
import { FormspreeProvider } from '@formspree/react';
import { useForm, ValidationError } from '@formspree/react';

class LayoutGuide extends React.Component {
  // This component displays rectangles in a bordered div
  // and reacts to Hover and Click events

  constructor(props) {
    super(props);

    this.state = {
      displayHoverLook: false,
      selected: false,
      rectanglesInFirstRow: props.rectsInFirstRow,
      rectanglesInSecondRow: props.rectsInSecondRow,
      callBackAPI: props.callBack,
      registerSelectedCallback: props.regSelCallback,
    }

    this.handleHover = this.handleHover.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOut = this.handleOut.bind(this);
    this.buildRectangles = this.buildRectangles.bind(this);
    this.clearSelected = this.clearSelected.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }

  handleClick() {
    //Add this layout to the main window
    this.state.callBackAPI(this.state.rectanglesInFirstRow, this.state.rectanglesInSecondRow);
    this.state.registerSelectedCallback(this.props.setRef);
    this.setState({selected: true});
  }

  handleHover() {
    //Highlight that this is clickable
    this.setState({displayHoverLook: true});
  }

  handleOut() {
    //Remove hover Highlight
    this.setState({displayHoverLook: false})
  }

  setSelected() {
    this.setState({selected: true});
  }

  clearSelected() {
    this.setState({selected: false});
  }

  buildRectangles(highlight, rectsInRow) {
    var row = [];
    var fullDiv = [];

    for (var i = 0; i < rectsInRow; i++) {

      var highlightOrRegularCLass = highlight? "rectangle_highlighted" : "rectangle"

      row.push(
            <React.Fragment>
                <div className={highlightOrRegularCLass}/>
            </React.Fragment>
      );
    }

    fullDiv.push(<div className="container-layout-row"> {row} </div>);

    return fullDiv;
  }

  render() {
    var classToUse = !this.state.displayHoverLook? "container-layout" : "container-layout layout-hover";
    classToUse = this.state.selected? "container-layout layout-selected" : classToUse;
    var highlightRectabgle = this.state.displayHoverLook? true : false;
    highlightRectabgle = this.state.selected? true : highlightRectabgle;

    return(
        <React.Fragment>
          <div  className={classToUse} onClick={this.handleClick} onMouseOver={this.handleHover} onMouseOut={this.handleOut}>
            {this.buildRectangles(highlightRectabgle, this.state.rectanglesInFirstRow)}
            {this.buildRectangles(highlightRectabgle, this.state.rectanglesInSecondRow)}
          </div>

        </React.Fragment>
  )}
}


class SbsVideo extends React.Component {
  // This component represents a single video element.
  // It includes controls to set the video source.

  constructor(props) {
    super(props);
    this.state = {
      sbsVideoSource: "",
      showInputForm: true,
      registerRefCallbackAPI: props.registerRefCallback
    }
    this.handleSrcAddButtonClick = this.handleSrcAddButtonClick.bind(this);
    this.renderInputForm = this.renderInputForm.bind(this);
    this.renderSrcAddButton = this.renderSrcAddButton.bind(this);
    this.handleSrcInoutChnage = this.handleSrcInoutChnage.bind(this);
    this.handleSrcFormSubmit = this.handleSrcFormSubmit.bind(this);

    this.myRef = React.createRef();
    this.fileInput = React.createRef();
  }

  componentDidMount() {
    // Called when component first mounts - i.e. when a SBS is first rendered
    // which may be when page is loaded or when a new loayout is chosen.
    // register this video ref back to the caller
    this.state.registerRefCallbackAPI(this.myRef);
  }

  componentDidUpdate(prevProps) {
    // Called when component is updated - i.e. when it is re-rendered due to
    // a new layout. Note that render otself may be called multiple times so
    // render itself is not a good place to put this - again register
    // this video ref back to the caller
    this.state.registerRefCallbackAPI(this.myRef);
  }

  handleSrcFormSubmit(e){
    const videoElement = this.myRef.current;
    this.myRef.current.load();
    this.setState({showInputForm: false});
    var videoFile = this.fileInput.current.files[0];
    if (videoFile) {
      if (videoElement.canPlayType(videoFile.type)) {
        var fileURL = URL.createObjectURL(this.fileInput.current.files[0]);
        this.setState({sbsVideoSource: fileURL});
      } else {
        alert("This video file cannot be played in this browser: " + this.fileInput.current.files[0].name);
      }
    }
    e.preventDefault();
  }

  handleSrcAddButtonClick() {
    //get the new updateSource
    this.setState({showInputForm: true});
  }

  handleSrcInoutChnage(e) {
    this.setState({sbsVideoSource: e.target.value});
  }

  renderInputForm() {
    //Display the input form for the video source
    return(
      <div className="video-tile video-src-input-area">
        <form onSubmit={this.handleSrcFormSubmit}>
          <label>URL </label>
          <input type="text" value={this.state.sbsVideoSource}  onChange={this.handleSrcInoutChnage}/>
          <input type="file" ref={this.fileInput}/>
          <input type="submit" value="add" className="default-button src-submit"/>
        </form>
      </div>
    )
  }

  renderSrcAddButton() {
    return(
      <div className="video-tile upadte-src-button-area">
        <button className="default-button" onClick={this.handleSrcAddButtonClick}>+</button>
      </div>
    )
  }

  render() {
    return(
      <div className="video-layout">
        <div className="video-tile">
          <video controls className="video-tile video_content" ref={this.myRef}>
            <source src={this.state.sbsVideoSource} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
          {this.state.showInputForm? this.renderInputForm() : this.renderSrcAddButton()}
        </div>
      </div>
    )
  }
}

class InfoDisplay extends React.Component {
  // This compoent allows the user create a single video by combining
  // the side by side videos in the main layout.

  constructor(props) {
    super(props);

    this.handleInfoAreaCancel = this.handleInfoAreaCancel.bind(this);
  }

  handleInfoAreaCancel(e) {
    e.preventDefault();
    this.props.canelFunction();
  }

  render() {
    var infoAreaHideShow = this.props.displayFlag? "overlay-area show-div" : "overlay-area hide-div"
    return (
      <div className={infoAreaHideShow}>
        <div className="overlay-inset-area">
          <p>
            This web app allows playback of a number of videos side by side or in a set of pre-defined layouts.
          </p>
          <p>
            Select the layout and then the source can be set for each video in the layout individually.
            Video playback can be synchronised or each individual video can be played separately if desired.
          </p>
          <p>
            The videos can either be from a web URL or from your local machine / desktop – there is no need to upload
            local videos before playback, avoiding the delays often seen with web based video editing applications.
          </p>
          <p>
            A consolidated video composite can also be requested, uploading the videos that have been tested side to side
            and receiving back a composite video. This feature is beta at this time.
          </p>
          <button className="default-button circle-button" onClick={this.handleInfoAreaCancel}>X</button>
        </div>
      </div>
    )
  }
}

class CompositeVideoRequest extends React.Component {
  // This compoent allows the user create a single video by combining
  // the side by side videos in the main layout.

  constructor(props) {
    super(props);

    this.state = {
      requesterName: "",
      requesterEmail: "",
      videoName: ""
    }

    this.handleRequesterNameChange = this.handleRequesterNameChange.bind(this);
    this.handleRequesterEmailChange = this.handleRequesterEmailChange.bind(this);
    this.handleVideoNameChange = this.handleVideoNameChange.bind(this);
    this.handleCompositeFormSubmit = this.handleCompositeFormSubmit.bind(this);
    this.handleCompositeFormCancel = this.handleCompositeFormCancel.bind(this);
  }

  handleRequesterNameChange(e) {
    this.setState({requesterName: e.target.value});
  }

  handleRequesterEmailChange(e) {
    this.setState({requesterEmail: e.target.value});
  }

  handleVideoNameChange(e) {
    this.setState({videoName: e.target.value});
  }

  handleCompositeFormSubmit(e) {
    //Email the form
    e.preventDefault();
  }

  handleCompositeFormCancel(e) {
    e.preventDefault();
    this.props.canelFunction();
  }

  render() {
    var compositeHideShow = this.props.displayFlag? "overlay-area show-div" : "overlay-area hide-div"
    return (
      <div className={compositeHideShow}>
        <div className="overlay-inset-area">
          <p>
            To create a single video combining the videos you are currently playing, please fill in the details below.
          </p>
          <p>
            Limmited availability free trial offer - usual price €10.
          </p>
          {<ContactForm cancelHandler={this.handleCompositeFormCancel}/>}
        </div>
      </div>
    )
  }
}

function ContactForm(props) {
  const [state, handleSubmit, reset] = useForm("contactForm");

  const clearForm = e => {
    e.preventDefault();
    reset();
    props.cancelHandler(e);
    return (
      <p>Cleared.</p>
    );
  }

  if (state.succeeded) {
      return (
        <React.Fragment>
          <p>Thanks! We will come back to you with more details.</p>
          <button className="default-button" onClick={clearForm}>Ok</button>
        </React.Fragment>
      );
  }
  return (
      <form onSubmit={handleSubmit}>
      <label htmlFor="email" className="form-element">
        Email Address
      </label>
      <input
        id="email"
        type="email"
        name="email"
        className="form-element"
      />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <label htmlFor="name" className="form-element">
        Name
      </label>
      <input
        id="name"
        name="name"
        className="form-element"
      />
      <ValidationError
        prefix="name"
        field="name"
        errors={state.errors}
      />
      <label htmlFor="videoname" className="form-element">
        Name for combined video
      </label>
      <input
        id="videoname"
        name="videoname"
        className="form-element"
      />
      <ValidationError
        prefix="videoname"
        field="videoname"
        errors={state.errors}
      />
      <button className="default-button src-submit" type="submit" disabled={state.submitting}>
        Submit
      </button>
      <input type="button" value="X" className="default-button circle-button" onClick={props.cancelHandler}/>
    </form>
  );
}

class SynchControl extends React.Component {
  // This component provides the controls to play, pause and
  // seek all videos together

  videos = [];

  constructor(props) {
    super(props);

    this.getRefsCallback = props.getVidRefsCallback;
    this.handlePlayButton = this.handlePlayButton.bind(this);
    this.handlePauseButton = this.handlePauseButton.bind(this);
  }

  handlePlayButton() {
    this.videos = this.getRefsCallback();
    for (var i=0; i< this.videos.length; i++) {
      this.videos[i].current.play();
    }
  }

  handlePauseButton() {
    this.videos = this.getRefsCallback();
    for (var i=0; i< this.videos.length; i++) {
      this.videos[i].current.pause();
    }
  }

  render() {
    return (
      <div className="container-layout-row">
        <div className="controls-container">
          <button className="default-button controls" onClick={this.handlePlayButton}>Play</button>
          <button className="default-button controls" onClick={this.handlePauseButton}>Pause</button>
        </div>
      </div>
    )
  }
}


class VideoLayout extends React.Component {
  // This component is the side by side video display component.
  // It displays the videos, allows the source be selected for each video
  // and provides the controls to the user.

  videoRefs = [];

  constructor(props) {
    super(props);

    this.state = {
      videosInFirstRow: props.vidsInFirstRow,
      videosInSecondRow: props.vidsInSecondRow,
      updateRefsCallback: props.vidRefUpdateCallback,
    }

    this.buildVideoDisplayRow = this.buildVideoDisplayRow.bind(this);
    this.registerVideoRefCallback = this.registerVideoRefCallback.bind(this);
    this.getVideoRefsCallBack = this.getVideoRefsCallBack.bind(this);
  }

  registerVideoRefCallback(videoRef) {
    this.videoRefs.push(videoRef);
  }

  getVideoRefsCallBack() {
    return this.videoRefs;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({videosInFirstRow: nextProps.vidsInFirstRow});
    this.setState({videosInSecondRow: nextProps.vidsInSecondRow});
    this.videoRefs = [];
  }

  buildVideoDisplayRow(vidInRow) {
    var vidRow = [];
    var fullVideoRowDiv = [];
    for (var i=0; i < vidInRow; i++) {
      vidRow.push(
        <React.Fragment>
          {<SbsVideo registerRefCallback={this.registerVideoRefCallback}/>}
        </React.Fragment>
      );
    }
    fullVideoRowDiv.push(<div className="container-layout-row"> {vidRow} </div>);
    return fullVideoRowDiv;
  }

  render() {
     return(
       <React.Fragment>
         <div className="container-layout no-bottom-border">
            {this.buildVideoDisplayRow(this.state.videosInFirstRow)}
            {this.buildVideoDisplayRow(this.state.videosInSecondRow)}
         </div>
         {<SynchControl getVidRefsCallback={this.getVideoRefsCallBack}/>}
       </React.Fragment>)
  }
}


class App extends React.Component {
  //This is the main class for the app

  constructor(props) {
    super(props);

    this.state = {
      mainVideoLayout_vidsInRow1: 2,
      mainVideoLayout_vidsInRow2: 0,
      videoRefArray: [],
      showCompositeForm: false,
      showInfoForm: false
    }

    //Can use array and loop to make more reusuable in future
    this.layout1ref = React.createRef();
    this.layout2ref = React.createRef();
    this.layout3ref = React.createRef();
    this.layout4ref = React.createRef();

    this.selectedLayout = this.layout1ref;

    this.updateMainLayout = this.updateMainLayout.bind(this);
    this.updateVideoRefs = this.updateVideoRefs.bind(this);
    this.updateSelectedLayout = this.updateSelectedLayout.bind(this);
    this.handleCreateCompositeButton = this.handleCreateCompositeButton.bind(this);
    this.hideCompositeForm = this.hideCompositeForm.bind(this);
    this.handleInfoArea = this.handleInfoArea.bind(this);
    this.hideInfoArea = this.hideInfoArea.bind(this);
  }

  componentDidMount() {
    this.layout1ref.current.setSelected();
    this.selected = this.layout1ref;
  }

  updateMainLayout(setVidsFirstRow, setVidsSecondRow) {
    this.setState({mainVideoLayout_vidsInRow1: setVidsFirstRow});
    this.setState({mainVideoLayout_vidsInRow2: setVidsSecondRow});
  }

  updateVideoRefs(newVideoRefArray) {
    this.setState({videoRefArray: newVideoRefArray});
  }

  updateSelectedLayout(layoutRef) {
    if (layoutRef) {
      this.selectedLayout.current.clearSelected();
    }
    this.selectedLayout = layoutRef;
  }

  handleCreateCompositeButton() {
    this.setState({showCompositeForm:true});
  }

  hideCompositeForm() {
    this.setState({showCompositeForm:false});
  }

  handleInfoArea() {
    this.setState({showInfoForm: true});
  }

  hideInfoArea() {
    this.setState({showInfoForm: false});
  }

  render() {
    return(
      <FormspreeProvider project="1852829219285892468">
        <div className="App">
          <header className="App-header">
            <p>
              <h2 className="headingtwo">Side-by-side Video</h2>
              <button className="default-button circle-button" onClick={this.handleInfoArea}>i</button>
            </p>
          </header>

          <div className="container1">

            <div className="container1-1" >
              <h2>Layouts</h2>
              {<LayoutGuide rectsInFirstRow="2" rectsInSecondRow="0" callBack={this.updateMainLayout} regSelCallback={this.updateSelectedLayout} setRef={this.layout1ref} ref={this.layout1ref}/>}
              {<LayoutGuide rectsInFirstRow="1" rectsInSecondRow="1" callBack={this.updateMainLayout} regSelCallback={this.updateSelectedLayout} setRef={this.layout2ref} ref={this.layout2ref}/>}
              {<LayoutGuide rectsInFirstRow="2" rectsInSecondRow="1" callBack={this.updateMainLayout} regSelCallback={this.updateSelectedLayout} setRef={this.layout3ref} ref={this.layout3ref}/>}
              {<LayoutGuide rectsInFirstRow="2" rectsInSecondRow="2" callBack={this.updateMainLayout} regSelCallback={this.updateSelectedLayout} setRef={this.layout4ref} ref={this.layout4ref}/>}
            </div>
            <div className="container1-2">
              {<VideoLayout vidsInFirstRow={this.state.mainVideoLayout_vidsInRow1}
                            vidsInSecondRow={this.state.mainVideoLayout_vidsInRow2}
                            vidRefUpdateCallback={this.updateVideoRefs}/>}
              <div className="container-layout-row">
                <div className="controls-container composite-controls">
                  <button className="default-button controls comp-controls" onClick={this.handleCreateCompositeButton}>Crete Composite Video</button>
                </div>
              </div>
              {<InfoDisplay displayFlag={this.state.showInfoForm} canelFunction={this.hideInfoArea}/>}
              {<CompositeVideoRequest displayFlag={this.state.showCompositeForm} canelFunction={this.hideCompositeForm}/>}
            </div>
          </div>

        </div>
      </FormspreeProvider>
    )
  }
}

export default App;
